@charset "utf-8";

///////////////////////////////////////////////////////////
// conversion
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-conversion {
  width: 100%;
  padding: clamp_size(60, 100) 0;
  background-image: image-set(url(img/common/img_conversion01.png) 1x, url(img/common/img_conversion01@2x.png) 2x);
  background-size: cover;
  background-position: center center;

  .c-btn {
    max-width: 27.6rem;
    margin: 0 auto;

    &--icon-l {
      .icon {
        background-color: #fff;
      }
    }
  }
}

.p-conversion__container {
  display: grid;
  gap: clamp_size(20, 30);
  // grid-template-columns: repeat(2, 1fr);
  width: min(92%, 105rem);
  margin: 0 auto;
  
  /* 採用情報 非表示用CSS */
  grid-template-columns: repeat(1, 50%);
  place-content: center;
  @include mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.p-conversion__content {
  padding: clamp_size(30, 40);
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
}

.p-conversion__body {
  margin-top: clamp_size(20, 30);
}

.p-conversion__content_tel_time {
  line-height: normal;
  margin-top: .5rem;
}

.p-conversion__content_note {
  width: 18em;
  margin: 0 auto;
}

.p-conversion__btn {
  margin-top: clamp_size(15, 25);

  &--recruit {
    .c-btn {
      width: 21rem;
    }
  }
}