@charset "utf-8";

///////////////////////////////////////////////////////////
// case
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// プロフィール
// ---------------------------------------------------------
.caseContent__head {
  display: grid;
  grid-template-columns: 1fr 56%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "case-thumb case-title"
    "case-thumb case-info";
  gap: 2.5rem 3rem;
  align-items: start;
  margin-top: 2rem;
  @include mq-down(lg) {
    grid-template-areas:
    "case-title case-title"
    "case-thumb case-info";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 0;
  }
  @include mq-down(md) {
    grid-template-columns: auto;
    grid-template-areas:
    "case-title"
    "case-thumb"
    "case-info";
  }
}

.caseContent__head_thumb {
  grid-area: case-thumb;

  img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 510 / 383;
  }
}

.caseContent__head_title {
  grid-area: case-title;
}

.caseContent__head_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  margin-top: 1.5rem;
  @include mq-down(lg) {
    margin-top: 1rem;
  }
}

.caseContent__head_info {
  grid-area: case-info;
}

.caseContent__head_name {
  font-weight: 700;
  .name {
    font-weight: inherit;
  }
}

.caseContent__head_profile {
  margin-top: .5rem;
}

// ---------------------------------------------------------
// コンテンツ
// ---------------------------------------------------------
.caseContent__lv3heading {
  position: relative;
  font-size: clamp_size(18, 22);
  font-weight: 700;
  line-height: normal;
  padding-bottom: .5em;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $clr_light_gray02;
  }
}

// ---------------------------------------------------------
// お問い合わせ
// ---------------------------------------------------------
.caseContent__conversion {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem clamp_size(26,56,768,1440);
  max-width: 96rem;
  margin: clamp_size(60, 110) auto 0;
  @include mq-down(md) {
    flex-direction: column;
  }

  &.c-box {
    padding: clamp_size(30, 40) clamp_size(20, 40);
  }
}

.caseContent__conversion_head {
  @include mq-down(md) {
    text-align: center;
  }
}

.caseContent__conversion_text {
  margin-top: 1.5rem;
  @include mq-down(md) {
    margin-top: 2rem;;
  }
}

.caseContent__conversion_btn {
  margin-top: 2rem;
  @include mq-down(md) {
    text-align: center;
  }
}

.caseContent__conversion_thumb {
  width: clamp_size(222,282,768,1440);
  @include mq-down(md) {
    max-width: 36rem;
    width: 100%;
    margin: 2rem auto 0;
  }
}