@charset "utf-8";

///////////////////////////////////////////////////////////
// archive
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-archive {
  margin-top: clamp_size(40, 70);
}

.p-archive__head {
  display: flex;
  justify-content: space-between;
  @include mq-down(sm) {
    flex-direction: column;
  }
}

.p-archive__head_taxonomy {
  font-weight: 700;
}

.p-archive__list {
  display: grid;
  gap: clamp_size(30, 50) clamp_size(15, 30);
  margin-top: 2rem;

  &--col1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  
  &--col3 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  &--col4 {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}