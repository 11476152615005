@charset "utf-8";

///////////////////////////////////////////////////////////
// btn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-btn {
  position: relative;
  display: inline-block;
  font-size: clamp_size(13, 16);
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: .95em 2.1em;
  max-width: 100%;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 40px;
  transition: color .3s, background-color .3s, border-color .3s ease-in-out;
  @include linkColor(#fff);

  > span {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  &--middle {
    width: clamp_size(226, 266);
  }

  &--large {
    width: 28.9rem;
  }

  &--xlarge {
    width: 38.5rem;
  }

  &--square {
    border-radius: 0;
  }

  &--green {
    color: #fff;
    background-color: $clr_green;
    @include hover {
      color: $clr_black;
      border: 2px solid $clr_green;
      background-color: #fff;
    }
  }

  &--yellow {
    background-color: $clr_yellow;
    @include linkColor($clr_black);
    @include hover {
      border: 2px solid $clr_yellow;
      background-color: #fff;
    }
  }

  &--white {
    color: $clr_green;
    border: 2px solid $clr_green;
    background-color: #fff;
    &:active,
    &:focus {
      color: $clr_green;
    }
    @include hover {
      color: #fff;
      background-color: $clr_green;
    }
  }

  &--icon-l {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .8em 2.05em .88em;

    .text {
      margin-left: .3em;
      transition: color .3s ease-in-out;
    }

    .icon {
      mask-repeat: no-repeat;
      mask-size: cover;
      transition: background-color .3s ease-in-out;
    }

    .icon--mail {
      width: 2.1rem;
      height: 2.1rem;
      mask-image: url("img/icon/icon_mail.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      transform: translateY(1px);
      @include mq-down(sm) {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &.c-btn--green {
      .text {
        color: #fff;
      }

      .icon {
        background-color: $clr_yellow;
      }

      @include hover {
        border: 2px solid $clr_green;
        background-color: #fff;

        .text {
          color: $clr_black;
        }

        .icon {
          background-color: $clr_green;
        }
      }
    }

    &.c-btn--yellow {
      .icon {
        background-color: $clr_green;
      }

      @include hover {
        border: 2px solid $clr_yellow;
        background-color: #fff;
      }
    }
  }

  &--arrow-r {
    padding: .95em 2.1em;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      mask-image: url("img/icon/icon_arrow_r.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      transition: background-color .3s ease-in-out;
    }

    &.c-btn--yellow {
      &::after {
        background-color: $clr_black;
      }
    }

    &.c-btn--green {
      &::after {
        background-color: #fff;
      }
    }

    @include hover {
      &.c-btn--green {
        &::after {
          background-color: $clr_black;
        }
      }
    }
  }

  &--arrow-l {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 1em;
      transform: translateY(-50%);
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      mask-image: url("img/icon/icon_arrow_l.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      transition: background-color .3s ease-in-out;
    }
    
    &.c-btn--yellow {
      &::after {
        background-color: $clr_black;
      }
    }

    &.c-btn--green {
      &::after {
        background-color: #fff;
      }
    }

    @include hover {
      &.c-btn--green {
        &::after {
          background-color: $clr_black;
        }
      }
    }
  }

  &--arrow-b {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      mask-image: url("img/icon/icon_arrow_b.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      transition: background-color .3s ease-in-out;
    }
    
    &.c-btn--yellow {
      &::after {
        background-color: $clr_black;
      }
    }

    &.c-btn--green {
      &::after {
        background-color: #fff;
      }
    }

    @include hover {
      &.c-btn--green {
        &::after {
          background-color: $clr_black;
        }
      }
    }
  }

  &--outlook {
    padding: .95em 2.5em;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      display: block;
      width: 2rem;
      height: 2rem;
      mask-image: url("img/icon/icon_outlook.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      background-color: $clr_green;
      transition: background-color .3s ease-in-out;
    }

    @include hover {
      &::after {
        background-color: #fff;
      }
    }
  }
}