@charset "utf-8";

///////////////////////////////////////////////////////////
// hoverScale
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-hoverScale {
  figure {
    overflow: hidden;
  }

  img {
    transition: transform .3s ease-in-out;
  }

  @include hover {
    img {
      transform: scale(1.05);
    }
  }
}