@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-heading {
  font-size: clamp_size(20, 28);
  font-weight: 700;
  line-height: 1.5;

  &--center {
    text-align: center;
  }

  &--small {
    font-size: clamp_size(17, 21);
    font-weight: 700;
    line-height: normal;
  }

  &--middle {
    font-size: clamp_size(18, 22);
    font-weight: 700;
    line-height: normal;
  }

  &--section {
    font-size: clamp_size(22, 32);
    line-height: 1;

    .en {
      position: relative;
      display: inline-block;
      font-size: calc(15 / 32 * 1em);
      font-family: $fnt_en;
      line-height: 2;
      color: $clr_green;
      padding: 0 1.4em;
      text-transform: capitalize;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: clamp_size(7, 11);
        height: clamp_size(18, 22);
      }

      &::before {
        left: 0;
        background-image: url("img/icon/icon_heading_l.svg");
        background-size: cover;
      }

      &::after {
        right: 0;
        background-image: url("img/icon/icon_heading_r.svg");
        background-size: cover;
      }

      &--noIcon {
        &::before,
        &::after {
          display: none;
        }
      }

      .upper {
        text-transform: uppercase;
      }

      .lower {
        text-transform: lowercase;
      }
    }

    .ja {
      display: block;
      font-size: inherit;
      font-weight: 700;
      margin-top: .2em;
      line-height: 1.3;
    }
  }
}