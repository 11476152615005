@charset "utf-8";

///////////////////////////////////////////////////////////
// recruit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.recruit__flex {
  display: flex;
  justify-content: space-between;
  gap: clamp_size(20, 30);
  margin-top: clamp_size(30, 40);
  @include mq-down(md) {
    flex-direction: column;
  }

  &--reverse {
    flex-direction: row-reverse;
    @include mq-down(md) {
      flex-direction: column;
    }
  }
}

.recruit__flex_image {
  width: calc(400 / 1300 * 100%);
  @include mq-down(md) {
    width: 100%;
    text-align: center;
  }
}

.recruit__flex_sentence {
  width: calc(833 / 1300 * 100%);
  @include mq-down(md) {
    width: 100%;
  }
}

.recruit__flex_title {
  font-weight: 500;
  margin-bottom: clamp_size(15, 20);
}

.recruit__flex_text {
  & + & {
    margin-top: 1.6em;
  }
}

// ---------------------------------------------------------
// チーム紹介
// ---------------------------------------------------------
.recruit__team_slider {
  position: relative;
  margin-top: clamp_size(30, 40);
  padding: 0 5.8rem;
  @include mq-down(sm) {
    padding: 0 clamp_size(40,100,375,576);
  }

  .swiper {
    padding-top: 0.5rem;
  }

  .swiper-slide {
    height: auto;
  }
  
  .p-card {
    height: 100%;
  }

  .swiper-button-prev {
    left: 0;
    @include mq-down(sm) {
      left: clamp_size(0,30,375,576);
    }
  }

  .swiper-button-next {
    right: 0;
    @include mq-down(sm) {
      right: clamp_size(0,30,375,576);
    }
  }

  .p-slicerBtn--next,
  .p-slicerBtn--prev {
    @include mq-down(sm) {
      width: clamp_size(30,44,375,576) !important;
      height: clamp_size(30,44,375,576) !important;
    }
  }

  &.is-stop {
    padding: 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-wrapper {
      gap: 3rem;
      justify-content: center;
      @include mq-down(sm) {
        flex-direction: column;
        align-items: center;
      }
    }

    .recruit__team_slide {
      width: 45%;
      max-width: 29.4rem;
      @include mq-down(sm) {
        width: 100%;
      }
    }
  }
}

.recruit__team_thumb {
  max-width: 17rem;
  margin: 0 auto;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
}

.recruit__team_body {
  display: grid;
  gap: .5rem;
  text-align: center;
  margin-top: 1.5rem;
}

.recruit__team_position,
.recruit__team_job,
.recruit__team_name,
.recruit__team_name_en {
  line-height: 1.64;
}

.recruit__team_name {
  font-size: calc(22 / 17 * 1em);
  font-weight: 700;
}

/* モーダル
---------------------------------------------------------- */
.recruit__modal_team {
  display: flex;
  justify-content: space-between;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.recruit__modal_team_head {
  width: 33%;
  @include mq-down(md) {
    width: 100%;
    text-align: center;
  }
}

.recruit__modal_team_thumb {
  @include mq-down(md) {
    max-width: 26rem;
    margin: 0 auto;
  }

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 375 / 380;
    border-radius: 10px;
  }
}

.recruit__modal_team_head_contents {
  display: grid;
  gap: .5rem;
  margin-top: 1.5rem;
}

.recruit__modal_team_job,
.recruit__modal_team_name,
.recruit__modal_team_name_en {
  line-height: 1.64;
}

.recruit__modal_team_name {
  font-weight: 700;
}

.recruit__modal_team_body {
  width: 64%;
  @include mq-down(md) {
    width: 100%;
    margin-top: 2rem;
  }
}

.recruit__modal_team_wrap {
  & + & {
    margin-top: clamp_size(20, 25);
  }
}

.recruit__modal_team_title {
  font-weight: 700;
  color: $clr_green;
}

// ---------------------------------------------------------
// 働く環境
// ---------------------------------------------------------
.recruit__environment_benefit {
  margin-top: clamp_size(30, 50);
}

.recruit__environment_benefitList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem 7.6%;
  place-content: center;
  margin-top: clamp_size(20, 40);
}

.recruit__environment_benefitItem {
  width: 33.3%;
  max-width: 26rem;
  min-width: 21rem;
  text-align: center;
}

.recruit__environment_benefitItem_title {
  margin-top: 1.5rem;
}

.recruit__environment_benefitItem_text {
  margin-top: 1rem;
}

.recruit__cardList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: clamp_size(15,30,992,1440);
  margin-top: clamp_size(40, 70);
  @include mq-down(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(sm) {
    grid-template-columns: 1fr;
  }
}


// ---------------------------------------------------------
// 現在募集中のポジション
// ---------------------------------------------------------
.recruit__recruitment {
  .c-postNone {
    margin-top: clamp_size(60, 110);
  }
}

.recruit__recruitment_units {
  display: grid;
  gap: 3rem;
  margin-top: clamp_size(30, 50);
}

.recruit__recruitment_table_wrap {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid $clr_gray;
  @include mq-down(sm) {
    flex-direction: column;
  }

  & + & {
    margin-top: 1rem;
  }
}

.recruit__recruitment_unit_title {
  width: clamp_size(80,200,576,1440);
  font-weight: 700;
  @include mq-down(sm) {
    width: 100%;
  }
}

.recruit__recruitment_unit_text {
  width: calc(100% - clamp_size(80,200,576,1440));
  @include mq-down(sm) {
    width: 100%;
  }
}

/* モーダル
---------------------------------------------------------- */
.recruit__modal_environment {
  display: flex;
  justify-content: space-between;
  @include mq-down(md) {
    flex-direction: column;
  }
}

.recruit__modal_environment_thumb {
  width: 33%;
  @include mq-down(md) {
    width: 100%;
    max-width: 38.5rem;
    margin: 0 auto;
  }

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 385 / 236;
    border-radius: 10px;
  }
}

.recruit__modal_environment_body {
  width: 63%;
  @include mq-down(md) {
    width: 100%;
    margin-top: 2rem;
  }
}

.recruit__modal_environment_title {
  font-weight: 700;
  @include mq-down(md) {
    text-align: center;
  }
}

.recruit__modal_environment_sentence {
  margin-top: 1.5rem;

  p {
    & + & {
      margin-top: 1.6em;
    }
  }
}

// ---------------------------------------------------------
// よくある質問
// ---------------------------------------------------------
.recruit__faq_container {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  margin-top: clamp_size(30, 40);
  @include mq-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include mq-down(sm) {
    gap: 2rem;
  }
}

.recruit__faq_box {
  border-radius: 10px;
  background-color: $clr_light_green;
}

.recruit__faq_wrap {
  display: flex;
}

.recruit__faq_icon {
  font-size: clamp_size(20, 28);
  font-weight: 700;
  font-family: $fnt_en;
  line-height: 1;
  color: $clr_green;
}

.recruit__faq_content {
  margin-left: clamp_size(15,20,768,1440);
}

.faqWrap {
  .l-section {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

/* Question
---------------------------------------------------------- */
.recruit__faq_question {
  position: relative;
  padding: 2rem 5rem 2rem clamp_size(15, 30);
  cursor: pointer;
  @include mq-down(md) {
    padding-right: clamp_size(36,42,375,768);
  }

  &::before,
  &::after {
    position: absolute;
    top: 3.2rem;
    right: 2rem;
    content: '';
    display: block;
    width: clamp_size(15, 18);
    height: .2rem;
    background-color: $clr_green;
    border-radius: 5px;
  }

  &::after {
    transform: rotate(90deg);
    transition: all .3s ease;
  }

  &.is-active {
    &::after {
      transform: rotate(0);
    }
  }
}

.recruit__faq_question_title {
  line-height: 1.6;
}

/* Answer
---------------------------------------------------------- */
.recruit__faq_answer {
  display: none;
  padding: 0 5rem 2rem clamp_size(15, 30);
  transition: opacity .3s ease;
  transition-delay: .2s;
  opacity: 0;
  @include mq-down(md) {
    padding-right: clamp_size(36,42,375,768);
  }

  &.is-active {
    opacity: 1;
    display: block;
  }

  .recruit__faq_icon {
    transform: translateY(1px);
  }
}

.recruit__faq_answer_text {
  text-align: justify;
  line-height: 1.6;
}