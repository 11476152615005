@use "../setting/variables" as *;

// .u-color {
//   &-black {
//     color: $clr_black;
//   }

//   &-gray {
//     color: $clr_gray;
//   }

//   &-blue {
//     color: $clr_blue;
//   }

//   &-red {
//     color: $clr_black;
//   }

//   &-white {
//     color: #fff;
//   }
// }

.u-text {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}

.u-font {
  &-small {
    font-size: 1.2rem;
  }

  &-size {
    @for $i from 10 through 30 {
      &-#{$i} {
        font-size: calc(#{$i} * 0.1rem);
      }
    }
  }

  &-bold {
    font-weight: bold;
  }
}