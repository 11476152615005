@charset "utf-8";

///////////////////////////////////////////////////////////
// index
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// FV
// ---------------------------------------------------------
.fv {
  overflow: hidden;
}

.fv__slider {
  position: relative;
  padding-bottom: clamp_size(32, 38);

  .swiper {
    overflow: visible;
    @include mq-down(sm) {
      overflow: hidden;
    }
  }

  .swiper-pagination {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    width: 1rem;
    height: 1rem;
    margin: 0 .6rem !important;
  }

  .swiper-pagination-bullet-active {
    background-color: $clr_green;
  }
}

.fv__slide {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(2deg, rgba(65, 79, 96, 0.60) 26.33%, rgba(217, 217, 217, 0.00) 90.97%);
    z-index: 1;
  }
}

.fv__slide_image {
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1266 / 581;
    border-radius: 10px;
  }
}

.fv__slide_text {
  position: absolute;
  left: clamp_size(20, 60);
  bottom: clamp_size(20, 60);
  font-size: clamp_size(18, 44);
  font-weight: 600;
  color: #fff;
  line-height: normal;
  z-index: 2;
}

// ---------------------------------------------------------
// エントランス
// ---------------------------------------------------------
.entrance {
  padding: clamp_size(40, 120) 0;
}

.entrance__inner {
  position: relative;
  width: min(92%, 105rem);
  margin: 0 auto;
}

.entrance__text {
  max-width: clamp_size(300, 643);
  margin: clamp_size(20, 30) auto 0;
}

.entrance__btn {
  margin-top: clamp_size(20, 30);
  text-align: center;
}

.entrance__object_l,
.entrance__object_r {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: clamp_size(70, 169);
}

.entrance__object_l {
  left: 0;
}

.entrance__object_r {
  right: 0;
}

// ---------------------------------------------------------
// 提供サービス
// ---------------------------------------------------------
.service {
  background-color: $clr_light_green;
}

.service__about {
  text-align: center;
  margin-top: clamp_size(20, 40);
}

.service__list {
  display: grid;
  gap: clamp_size(15,30,992,1440);
  grid-template-columns: repeat(4, 1fr);
  margin-top: clamp_size(30, 40);
  @include mq-down(lg) {
    grid-template-columns: repeat(2, 1fr);
    gap: clamp_size(15,20,375,992);
  }
  @include mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

// ---------------------------------------------------------
// 事例紹介
// ---------------------------------------------------------
.case {
  overflow: hidden;
}

.case__about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: clamp_size(30, 50);
  @include mq-down(lg) {
    flex-direction: column;
  }
}

.case__about_result {
  width: calc(260 / 1146 * 100%);
  text-align: center;
  @include mq-down(lg) {
    width: 100%;
  }
}

.case__about_result_title {
  font-size: clamp_size(16, 22);
  font-weight: 700;
}

.case__about_result_num {
  font-size: clamp_size(23,27,992,1440);
  font-weight: 700;
  @include mq-down(lg) {
    font-size: clamp_size(23,27,375,992);
  }

  .num {
    display: inline-block;
    font-size: calc(60 / 27 * 1em);
    font-weight: 700;
    font-family: $fnt_en;
    color: $clr_green;
    line-height: 1;
    margin-right: .1em;
  }
}

.case__about_text {
  width: calc(824 / 1146 * 100%);
  @include mq-down(lg) {
    width: 100%;
    margin-top: 1.5rem;
  }
}

.case__slider {
  position: relative;
  margin-top: clamp_size(40, 70);
  transition: transform .7s ease-in-out;

  .swiper {
    overflow: visible;
    padding-top: 6rem;
    @include mq-down(xl) {
      padding-top: 4rem;
    }
    @include mq-down(md) {
      padding-top: 3rem;
    }
    @include mq-down(sm) {
      padding-top: 0;
      overflow: hidden;
    }
  }

  .swiper-slide {
    position: relative;
    transition: transform .7s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      transition: opacity .7s, visibility .7s ease-in-out;
    }
  }

  .swiper-slide img {
    height: auto;
    width: 100%;
  }

  .swiper-slide[class*=-active] {
    max-width: 100%;
    z-index: 1;
    transform: translateY(-6rem) translateZ(0) !important;
    @include mq-down(xl) {
      transform: translateY(-4rem) translateZ(0) !important;
    }
    @include mq-down(md) {
      transform: translateY(-3rem) translateZ(0) !important;
    }
    @include mq-down(sm) {
      transform: translateY(0) translateZ(0) !important;
    }

    &::before {
      opacity: 0;
      visibility: hidden;
    }

    .case__slide_link {
      pointer-events: auto;
    }

    .case__slide_info_text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 0 !important;
    margin-top: calc((93% / 1.95 * 0.6 / 2 - 22px));
    @include mq-down(lg) {
      margin-top: calc((93% / 1.5 * 0.6 / 2 - 22px));
    }
    @include mq-down(md) {
      margin-top: calc(93% / 1.25 * 0.6 / 2 - 22px);
    }
    @include mq-down(sm) {
      margin-top: calc(100% / 1 * 0.6 / 2 - 22px);
    }
  }

  .swiper-button-prev {
    left: clamp_size(210,305,992,1440) !important;
    @include mq-down(lg) {
      left: clamp_size(100,135,768,992) !important;
    }
    @include mq-down(md) {
      left: clamp_size(35,50,576,768) !important;
    }
    @include mq-down(sm) {
      left: -1rem !important;
    }
  }
  
  .swiper-button-next {
    right: clamp_size(210,305,992,1440) !important;
    @include mq-down(lg) {
      right: clamp_size(100,135,768,992) !important;
    }
    @include mq-down(md) {
      right: clamp_size(35,50,576,768) !important;
    }
    @include mq-down(sm) {
      right: -1rem !important;
    }
  }

  .swiper-pagination {
    position: static;
    display: flex;
    justify-content: center;
    gap: .5rem;
    margin-top: 2rem;
  }

  .swiper-pagination-bullet {
    background-color: $clr_light_gray01;
  }
  
  .swiper-pagination-bullet-active {
    background-color: $clr_green;
  }

  &.is-stop {
    .case__slide {
      max-width: 62rem;
      margin: 0 auto;
      opacity: 1;
    }

    .case__slide_link {
      pointer-events: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-slide {
      &::before {
        display: none;
      }
    }
  }
}

.case__slide_link {
  display: block;
  pointer-events: none;

  @include hover {
    .case__slide_image {
      border-color: $clr_green;
    }
  }
}

.case__slide_image {
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid transparent;
  transition: border-color .4s ease-in-out;
  
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 616 / 366;
    border-radius: 10px;
  }
}

.case__slide_body {
  margin-top: 1.5rem;
}

.case__slide_client {
  font-size: clamp_size(12, 14);

  .name,
  .unit {
    display: inline-block;
  }

  .unit {
    margin-left: .2em;
  }
}

.case__slide_title {
  font-weight: 700;
  line-height: 1.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.case__slide_info {
  margin-top: 1.5rem;
}

.case__slide_info_wrap {
  display: flex;

  & + & {
    margin-top: .2rem;
  }
}

.case__slide_info_title,
.case__slide_info_text {
  line-height: 1.4;
}

.case__slide_info_title {
  font-weight: 700;
  width: 5em;
}

.case__slide_info_text {
  width: calc(100% - 5em);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.case__listBtn {
  margin-top: clamp_size(30, 50);
  text-align: center;
}

/* 出版書籍
---------------------------------------------------------- */
.case__book {
  margin-top: clamp_size(40, 70);
}

.case__bookList {
  display: grid;
  gap: clamp_size(20,30,992,1440);
  grid-template-columns: repeat(4, 1fr);
  margin-top: clamp_size(15, 25);
  @include mq-down(lg) {
    gap: 3rem 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.case__bookItem {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.case__bookItem_link {
  display: inline-block;
}

.case__bookItem_title {
  line-height: 1.7;
  margin: clamp_size(10, 20) 0 1.5rem;
}

.case__bookItem_btn {
  margin-top: auto;

  .c-btn {
    width: 100%;
    max-width: 22.5rem;
    margin: 0 auto;
  }
}

/* 登壇実績
---------------------------------------------------------- */
.case__seminar {
  margin-top: clamp_size(30, 50);
}

.case__seminar_inner {
  display: flex;
  justify-content: space-between;
  gap: clamp_size(15, 30);
  margin-top: clamp_size(15, 25);
  @include mq-down(lg) {
    flex-direction: column;
  }
}

.case__seminar_thumb {
  width: 36%;
  @include mq-down(lg) {
    width: 100%;
    text-align: center;
  }

  img {
    border-radius: 10px;
  }
}

.case__seminar_contents {
  width: 62%;
  @include mq-down(lg) {
    width: 100%;
  }
}

.case__seminar_list {
  @include mq-down(lg) {
    max-width: 62rem;
    margin: 0 auto;
  }
}

.case__seminar_item {
  font-size: clamp_size(14, 17);
  line-height: 1.8;

  &::before {
    content: "・";
    font-size: clamp_size(14, 17);
  }

  & + & {
    margin-top: .4rem;
  }
}

.case__seminar_item_link {
  text-decoration: underline;
  text-underline-offset: 0.2em;

  @include hover {
    text-decoration: none;
  }
}

.case__seminar_btn {
  margin-top: clamp_size(15, 25);
  @include mq-down(lg) {
    text-align: center;
  }
}

// ---------------------------------------------------------
// コラム
// ---------------------------------------------------------
.column {
  background-color: $clr_light_green;
  overflow: hidden;

  .c-postNone {
    display: grid;
    place-content: center;
    height: 100%;
    margin-top: 0;
  }
}

.column__sliderBtn {
  display: flex;
  margin-top: clamp_size(15, 30);
  @include mq-down(md) {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin-top: 0;
  }

  .swiper-button-next {
    margin-left: clamp_size(10, 20);
  }
}

.column__slider {
  position: relative;
  margin-right: calc(50% - 50vw);
  @include mq-down(md) {
    width: 100%;
    margin: 2.5rem auto 0;
  }

  .swiper {
    padding-top: .5rem;
    padding-right: 4vw;
    @include mq-down(md) {
      padding-right: 0;
      overflow: visible;
    }
    @media screen and (max-width: 460px) {
      overflow: hidden;
    }
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    @include mq-down(md) {
      display: flex;
    }
  }

  .swiper-button-prev {
    @include mq-down(md) {
      left: clamp_size(35,50,576,768) !important;
    }
    @media screen and (max-width: 460px) {
      left: -1rem !important;
    }
  }

  .swiper-button-next {
    @include mq-down(md) {
      right: clamp_size(35,50,576,768) !important;
    }
    @media screen and (max-width: 460px) {
      right: -1rem !important;
    }
  }

  .p-columnCard {
    height: 100%;
  }

  &--postNone {
    margin-right: 0;
  }
}

// ---------------------------------------------------------
// お知らせ
// ---------------------------------------------------------
.news {
  .c-postNone {
    display: grid;
    place-content: center;
    height: 100%;
    margin-top: 0;
  }
}

.news__contents {
  @include mq-down(lg) {
    margin-top: 3rem;
  }
}