@charset "utf-8";

///////////////////////////////////////////////////////////
// card
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-card {
  transition: transform .3s ease-in-out;

  &--modal {
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 4.6rem;
      height: 4.6rem;
      background-color: $clr_green;
      border-radius: 0 0 10px 0;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }

    &::after {
      position: absolute;
      right: .7rem;
      bottom: .7rem;
      content: "";
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      mask-image: url("img/icon/icon_plus.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      background-color: #fff;
    }
  }

  @include hover {
    transform: translateY(-.5rem);
  }
}

.p-card__link {
  display: block;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.p-card__thumb {
  text-align: center;
}

.p-card__title {
  font-size: clamp_size(16,20,992,1440);
  font-weight: 700;
  text-align: center;
  margin-top: 1rem;

  .note {
    display: block;
    font-size: calc(16 / 20 * 1em);
  }
}

.p-card__text {
  line-height: 1.64;
  margin-top: .5rem;
}