@charset "utf-8";

///////////////////////////////////////////////////////////
// privacy
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.privacy__text {
  margin-top: .8em;
  line-height: 1.8;
}

.privacy__list {
  margin-top: clamp_size(20, 25);
}

.privacy__subList {
  margin-top: clamp_size(15, 20);
}

.privacy__item,
.privacy__subItem {
  line-height: 1.8;
}

.privacy__item {
  & + & {
    margin-top: clamp_size(20, 30);
  }
  
}