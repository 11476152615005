@charset "utf-8";

///////////////////////////////////////////////////////////
// contact
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.contact__tel {
  text-align: center;
}

.contact__tel_num {
  margin-top: 2rem;

  .c-tel {
    font-size: clamp_size(32, 52);

    &::before {
      width: clamp_size(27, 37);
      height: clamp_size(27, 37);
    }
  }
}

.contact__tel_time {
  margin-top: .5rem;
}

.contact__mail_head {
  margin-top: clamp_size(25, 35);
}
