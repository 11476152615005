@charset "utf-8";

///////////////////////////////////////////////////////////
// footer
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 6rem 0 3rem;
  @include mq-down(lg) {
    flex-direction: column;
  }
  @include mq-down(md) {
    padding: 4rem 0 2rem;
  }
}

.footer__logo {
  width: 20rem;
  @include mq-down(lg) {
    margin: 0 auto;
  }
}

.footer__logo_link {
  display: inline-block;
}

.footer__nav {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  gap: clamp_size(40,133,992,1440);
  max-width: 105rem;
  @include mq-down(lg) {
    margin: clamp_size(20,30,375,992) auto 0;
  }
  @include mq-down(md) {
    gap: clamp_size(10,15,576,768);
  }
  @include mq-down(sm) {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "footerNavList-01"
      "footerNavList-02"
      "footerNavList-03"
      "footerNavList-04";
    }
}

.footer__navList {
  @include mq-down(sm) {
    display: grid;
    gap: 0.5rem;
    
    &:nth-child(1) {
      grid-area: footerNavList-01;
    }
    &:nth-child(2) {
      grid-area: footerNavList-02;
    }
    &:nth-child(3) {
      grid-area: footerNavList-03;
    }
    &:nth-child(4) {
      display: grid;
      gap: .5rem 1rem;
      grid-template-columns: repeat(2, auto);
      grid-area: footerNavList-04;
    }
  }
}

.footer__navSubList {
  margin-left: 2rem;
  @include mq-down(sm) {
    display: grid;
    gap: .5rem 1rem;
    grid-template-columns: repeat(2, auto);
    margin-left: 1.5rem;
  }
}

.footer__navLink {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  padding-left: 1.5em;
  @include mq-down(md) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 620px) {
    font-size: 1.3rem;
  }
  @include mq-down(sm) {
    padding-left: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    mask-image: url("img/icon/icon_arrow_r.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: $clr_green;
    width: 2.4rem;
    height: 2.4rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s, visibility .3s ease-in-out;
    @include mq-down(sm) {
      display: none;
    }
  }

  @include hover {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
}

.footer__copy {
  display: grid;
  place-content: center;
  height: clamp_size(30, 58);
}

.footer__copy_text {
  font-size: clamp_size(10, 12);
  text-align: center;
}