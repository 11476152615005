@charset "utf-8";

///////////////////////////////////////////////////////////
// contents
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-contents {
  padding: clamp_size(60, 100) 0 clamp_size(70, 120);

  &--noBottom {
    padding-bottom: 0;
  }
}