@charset "utf-8";

///////////////////////////////////////////////////////////
// pager
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: clamp_size(50, 70);

  a,
  span {
    padding: 0 !important;
  }

  .page,
  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 clamp_size(5, 10);
  }
  
  .page,
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp_size(13, 16);
    width: clamp_size(30, 50);
    height: clamp_size(30, 50);
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    position: relative;
    color: $clr_green;
    border: 2px solid $clr_green !important;
    background-color: #fff;
    transition: color .3s, background-color .3s, border-color .3s ease-in-out;

    @include hover {
      color: #fff;
      background-color: $clr_green;
      border: 2px solid $clr_green !important;
    }
  }

  span.current {
    color: #fff;
    background-color: $clr_green;
    border: 2px solid $clr_green !important;
    pointer-events: none;
    box-sizing: content-box;
  }

  .previouspostslink,
  .nextpostslink {
    &::after {
      content: "";
      display: inline-block;
      mask-image: url("img/icon/icon_arrow_r.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      background-color: $clr_green;
      width: 2.2rem;
      height: 2.2rem;
      vertical-align: middle;
      transition: background-color .3s ease-in-out;
    }
    @include hover {
      &::after {
        background-color: #fff;
      }
    }
  }

  .previouspostslink {    
    &::after {
      transform: rotate(180deg);
    }
  }

  .first,
  .last {
    display: none;
  }

  .extend {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr_green;
    width: 1rem;
    height: 1rem;
    border: none;
    transform: translateY(-25%);
  }
}