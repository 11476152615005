@charset "utf-8";

///////////////////////////////////////////////////////////
// box
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-box {
  border: 2px solid $clr_green;
  border-radius: 10px;
  background-color: #fff;
  padding: clamp_size(20, 30);

  &--xsmall {
    padding: clamp_size(15, 30) 1rem;
  }

  &--small {
    padding: clamp_size(15, 20);
  }

  &--large {
    padding: clamp_size(30, 60);
  }

  &--green {
    background-color: $clr_light_green;
  }

  &--noBorder {
    border: none;
  }
}
