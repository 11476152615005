@charset "utf-8";

///////////////////////////////////////////////////////////
// about
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// Mission
// ---------------------------------------------------------
.about__mission_container {
  display: flex;
  justify-content: space-between;
  gap: clamp_size(20, 30);
  margin-top: clamp_size(30, 40);
  @include mq-down(lg) {
    flex-direction: column;
  }
}

.about__mission_images {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 38%;
  @include mq-down(lg) {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
  }
  @include mq-down(sm) {
    flex-direction: column;
    align-items: center;
  }
}

.about__mission_sentence {
  width: 58%;
  @include mq-down(lg) {
    width: 100%;
  }
}

.about__mission_text {
  line-height: 1.8;

  & + & {
    margin-top: 1.6em;
  }
}

// ---------------------------------------------------------
// Value
// ---------------------------------------------------------
.about__value_list {
  display: grid;
  gap: clamp_size(20, 30);
  grid-template-columns: repeat(1, 1fr);
  margin-top: clamp_size(30, 40);
}

.about__value_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq-down(lg) {
    flex-direction: column;
  }
}

.about__value_head {
  width: 28%;
  text-align: center;
  @include mq-down(lg) {
    width: 100%;
  }
}

.about__value_head_title {
  font-size: clamp_size(20,25,992,1440);
  font-weight: 700;
  line-height: 1.3;
}

.about__value_sentence {
  width: 68%;
  @include mq-down(lg) {
    width: 100%;
    margin-top: 2rem;
  }
}

// ---------------------------------------------------------
// CEO message
// ---------------------------------------------------------
.about__message_container {
  display: flex;
  justify-content: space-between;
  margin-top: clamp_size(40, 80);
  @include mq-down(md) {
    flex-direction: column-reverse;
  }
}

.about__message_content {
  width: 64%;
  @include mq-down(md) {
    width: 100%;
    margin-top: clamp_size(20, 30);
  }
}

.about__message_sentence {
  margin-top: clamp_size(20, 40);
}

.about__message_text {
  & + & {
    margin-top: 1.6em;
  }
}

.about__message_bnr {
  margin-top: clamp_size(40, 80);
}

.about__message_bnr_link {
  display: block;
}

// ---------------------------------------------------------
// 会社概要
// ---------------------------------------------------------
.about__company_list {
  margin-top: clamp_size(30, 40);
  border-bottom: 1px solid $clr_light_gray02;
}

.about__company_wrap {
  display: flex;
  gap: clamp_size(40,80,768,1440);
  padding: 2rem 0;
  border-top: 1px solid $clr_light_gray02;
  @include mq-down(sm) {
    flex-direction: column;
    gap: .5rem;
  }
}

.about__company_title {
  width: 8em;
  font-weight: 700;
  line-height: 1.8;
  @include mq-down(sm) {
    width: 100%;
  }
}

.about__company_text {
  width: calc(100% - 8em);
  line-height: 1.8;
  @include mq-down(sm) {
    width: 100%;
  }
}

.about__company_info {
  & + & {
    margin-top: 2rem;
  }
}

.about__company_info_title {
  position: relative;
  line-height: 1.8;

  &::before {
    content: "■";
    font-size: clamp_size(14, 17);
    color: $clr_green;
  }

  & + a {
    margin-top: .25em;
  }
}

.about__company_info_text {
  line-height: 1.8;

  & + a {
    margin-top: .25em;
  }
}

.about__company_info_link {
  display: inline-block;
  font-weight: 700;
  color: $clr_green;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  line-height: 1.8;
  @include linkColor($clr_green);
  
  @include hover {
    text-decoration: none;
  }
}

.about__company_logos {
  display: flex;
  gap: clamp_size(20, 30);
  margin-top: clamp_size(30, 50);
}

.about__company_logo {
  
  &:nth-child(1) {
    width: 55%;
    max-width: 30rem;
  }
  
  &:nth-child(2) {
    width: 45%;
    max-width: 20rem;
  }
}

