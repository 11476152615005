@charset "utf-8";

///////////////////////////////////////////////////////////
// ceo
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-ceo {
  width: 30%;
  @include mq-down(md) {
    width: 100%;
    max-width: 34rem;
    margin: 0 auto;
  }
}

.p-ceo__profile {
  text-align: right;
  line-height: 1.8;
  margin-top: 1rem;

  .name {
    display: block;
    font-size: calc(22 / 17 * 1em);
    font-weight: 700;
  }
}