@charset "utf-8";

///////////////////////////////////////////////////////////
// tag
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-tag {
  display: flex;
  
  .icon {
    font-size: clamp_size(13, 15);
    font-weight: 500;
    color: $clr_green;
  }

  .name {
    font-size: clamp_size(13, 15);
    font-weight: 500;
    color: $clr_green;
  }

  &--link {
    @include hover {
      .name {
        color: $clr_green;
        text-decoration: underline;
        text-underline-offset: .1em;
      }
    }
  }

}