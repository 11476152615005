@charset "utf-8";

///////////////////////////////////////////////////////////
// suvFv
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-subFv {
  display: grid;
  place-content: center;
  height: clamp_size(205, 245);
}

.p-subFv__inner {
  position: relative;
  width: clamp_size(300, 600);
  margin: 0 auto;
}

.p-subFv__object_l,
.p-subFv__object_r {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: clamp_size(61, 81);
}

.p-subFv__object_l {
  left: 0;
}

.p-subFv__object_r {
  right: 0;
}