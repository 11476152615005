@charset "utf-8";

///////////////////////////////////////////////////////////
// date
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-date {
  time {
    font-size: clamp_size(12, 14);
    font-family: $fnt_en;
    font-weight: 600;
    color: $clr_middle_gray;
  }
}