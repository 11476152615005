@charset "utf-8";

///////////////////////////////////////////////////////////
// section
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-section {
  padding: clamp_size(60, 110) 0;

  &--green {
    background-color: $clr_light_green;
  }

  &--pb120 {
    padding-bottom: clamp_size(70, 120);
  }

  &--mt70 {
    margin-top: clamp_size(40, 70);
  }

  &--mt100 {
    margin-top: clamp_size(50, 100);
  }

  &--mt110 {
    margin-top: clamp_size(60, 110);
  }

  &--mt120 {
    margin-top: clamp_size(70, 120);
  }
}