@charset "utf-8";

///////////////////////////////////////////////////////////
// modal
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: none;
  z-index: 999;

  &.is-modalOpen {
    display: block;
  }

  /* animation
  ---------------------------------------------------------- */
  &[aria-hidden="false"] .p-modal__overlay,
  &[aria-hidden="false"] .p-modal__content {
    animation: modalShow 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }
  
  &[aria-hidden="true"] .p-modal__overlay,
  &[aria-hidden="true"] .p-modal__content {
    animation: modalClose 0.8s cubic-bezier(0.34, 0.07, 0.095, 0.995);
  }
  
  @keyframes modalShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes modalClose {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.p-modal__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(8, 8, 8, 0.50);
}

.p-modal__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(87vw, 126.4rem);
  max-height: 90%;
}

.p-modal__content {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
}

.p-modal__inner {
  width: 100%;
  max-height: 75vh;
  max-height: 75dvh;
  padding: clamp_size(30, 60);
  overflow-y: auto;
}

.p-modal__close {
  position: absolute;
  width: 7.8rem;
  height: 7.8rem;
  top: -3.5rem;
  right: -3.5rem;
  border-radius: 50%;
  background-color: $clr_green;
  border: 2px solid transparent;
  transition: border-color .3s, background-color .3s ease-in;
  @include mq-down(xl) {
    width: 6.8rem;
    height: 6.8rem;
    right: -2.5rem;
    top: -2.5rem;
  }
  @include mq-down(md) {
    width: 5.8rem;
    height: 5.8rem;
  }
  @include mq-down(sm) {
    width: 4.8rem;
    height: 4.8rem;
    right: -1.5rem;
    top: -1.5rem;
  }
  
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: clamp_size(52,62,992,1440);
    height: clamp_size(52,62,992,1440);
    mask-image: url("img/icon/icon_cross.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: #fff;
    transition: background-color .4s ease;
    @include mq-down(lg) {
      width: 5.2rem;
      height: 5.2rem;
    }
    @include mq-down(md) {
      width: 4.2rem;
      height: 4.2rem;
    }
  }

  @include hover {
    border: 2px solid $clr_green;
    background-color: #fff;
    &::before {
      background-color: $clr_green;
    }
  }
}