@charset "utf-8";

///////////////////////////////////////////////////////////
// aside
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-aside {
  @include mq-down(md) {
    max-width: 46rem;
    margin: 0 auto;
  }
}

.p-aside__content {
  margin-bottom: clamp_size(30, 40);

  &:last-child {
    margin-bottom: 0;
  }
}

.p-aside__content_title {
  font-size: clamp_size(18, 22);
  font-weight: 700;
  margin-bottom: .5rem;
}

// ---------------------------------------------------------
// この記事を書いた人
// ---------------------------------------------------------
.p-aside__profile_head {
  display: flex;
  align-items: center;
}

.p-aside__profile_image {
  max-width: 6.2rem;
  margin-right: 1rem;

  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
}

.p-aside__profile_name {
  .company,
  .position,
  .name {
    display: block;
    font-size: clamp_size(12, 15);
    line-height: normal;
  }
}

.p-aside__profile_text {
  font-size: clamp_size(13, 16);
  line-height: 1.6;
  margin-top: clamp_size(10, 15);
}

.p-aside__profile_otherPost {
  margin-top: clamp_size(10, 15);
}

.p-aside__profile_otherPost_link {
  font-size: clamp_size(13, 15);
  font-weight: 700;
  color: $clr_green;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  @include linkColor($clr_green);

  @include hover {
    text-decoration: none;
  }
}