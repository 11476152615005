@charset "utf-8";

///////////////////////////////////////////////////////////
// select
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-select {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    mask-image: url("img/icon/icon_arrow_b.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: $clr_green;
  }

  select {
    width: 100%;
    padding: 1.1em;
    font-size: clamp_size(13,16);
    font-weight: 700;
    color: $clr_black;
    background-color: $clr_light_gray03;
    border-radius: 5px;
  }
}
