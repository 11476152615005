@charset "utf-8";

///////////////////////////////////////////////////////////
// header
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 11.5rem;
  z-index: $z-header;
  @include mq-down(md) {
    height: 8.5rem;
  }
  @include mq-down(sm) {
    height: 6.5rem;
  }

  &.is-fixed {
    position: fixed;
    animation: headerSlideDown 1s;
    top: 0;
    left: 0;
    height: 7rem;
    background-color: $clr_beige;
    @include mq-down(md) {
      height: 6.4rem;
    }

    .header__navContactList {
      @include mq-down(sm) {
        display: none;
      }
    }

    .header__navLink {
      &::before {
        top: -.3em;
        @include mq-down(lg) {
          top: 50%;
        }
      }
    }
  }

  @keyframes headerSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding: 0 4%;
  margin: 0 auto;
}

.header__logo {
  width: clamp_size(109,129,992,1440);
  @include mq-down(lg) {
    width: clamp_size(109,129,375,992);
  }
}

.header__logo_link {
  display: block;
}

.header__nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
  margin-left: auto;
}

.header__navTopItem_telTime {
  font-size: clamp_size(12,13,768,1440);
  line-height: normal;
  margin-left: 1rem;
}

.header__navList {
  display: flex;
  align-items: center;
  @include mq-down(lg) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    width: clamp_size(260,300,375,768);
    height: auto;
    padding: clamp_size(80,100,375,768) clamp_size(30,40,375,768) clamp_size(40,80,375,768);
    background-color: $clr_beige;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    overflow-y: auto;
    transform: translate3d(100%, 0, 0);
    transition: all .6s cubic-bezier(.47, .16, .24, 1);
    z-index: $z-nav;

    &.is-open {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translate3d(0,0,0);
      z-index: $z-nav;
    }
  }
}

.header__navItem {
  & + & {
    margin-left: clamp_size(15,30,992,1440);
    @include mq-down(lg) {
      margin-top: clamp_size(15,20,375,768);
      margin-left: 0;
    }
  }

  &--consultation {
    @include mq-down(lg) {
      margin-top: 3rem !important;
    }
  }

  &--contact {
    margin-left: 1.5rem !important;
    @include mq-down(lg) {
      margin-left: 0 !important;
      margin-top: 1.5rem !important;
    }
  }

  .c-btn {
    font-size: 1.4rem;
    min-width: 13.6rem;
    padding: .6em 1.5em .7em;
    @include mq-down(lg) {
      width: 100%;
    }
  }
}

.header__navLink {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  transition: color .3s ease-in-out;
  @include mq-down(lg) {
    font-size: clamp_size(15,18,375,992);
    padding-left: .85em;
  }

  &::before {
    content: "";
    position: absolute;
    top: -.6em;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $clr_green;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s,visibility .3s ease-in-out;
    @include mq-down(lg) {
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  @include hover {
    color: $clr_green;
    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header__navContactList {
  display: none;
  @include mq-down(lg) {
    display: flex;
    align-items: center;
  }
  @include mq-down(sm) {
    display: none;
  }
}

.header__navContactItem {
  & + & {
    margin-left: 1rem;
    @include mq-down(sm) {
      margin-left: .5rem;
    }
    @media screen and (max-width: 460px) {
      margin-top: .5rem;
      margin-left: 0;
    }
  }

  .c-btn {
    font-size: 1.4rem;
    min-width: 13.6rem;
    padding: .6em 1.5em .7em;
  }
}

/* ハンバーガーメニュー（SP）
---------------------------------------------------------- */
.header__hamburger {
  display: none;
  @include mq-down(lg) {
    position: relative;
    display: grid;
    place-content: center;
    width: clamp_size(46,56,375,992);
    height: clamp_size(46,56,375,992);
    border-radius: 50%;
    margin-left: clamp_size(10,15,576,992);
    background-color: $clr_green;
    z-index: $z-hamburger;
  
    &.is-open {  
      .header__hamburger_bar {
        &:nth-child(1) {
          transform: translate(-50%,clamp_size(7,9,375,992)) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translate(-100%, -50%);
        }
        &:nth-child(3) {
          transform: translate(-50%,clamp_size(-9,-6,992,375)) rotate(-45deg);
        }
      }
    }
  }
  @media screen and (max-width: 460px) {
    margin-left: 1.5rem;
  }
}

.header__hamburger_inner {
  position: relative;
  display: inline-block;
  width: clamp_size(25,30,375,992);
  height: clamp_size(15,20,375,992);
  margin: 0 auto;
}

.header__hamburger_bar {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 2px;
  transition: all .4s ease-out;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .2s, transform .4s ease-out;
  }
  &:nth-child(3) {
    bottom: 0;
  }
}

/* メニュー展開時 背景
---------------------------------------------------------- */
.header__bg {
  display: none;
  @include mq-down(lg) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #00000080;
    opacity: 0;
    visibility: hidden;
    transition: opacity .6s,visibility .6s cubic-bezier(.47, .16, .24, 1);
    z-index: $z-headerBg;
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}

/* メニュー展開時 背景固定
---------------------------------------------------------- */
body.is-open {
  height: 100%;
  overflow: hidden;
}