@charset "utf-8";

///////////////////////////////////////////////////////////
// topFlexBox
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-topFlexBox {
  display: flex;
  gap: clamp_size(30,100,768,1440);
  @include mq-down(md) {
    flex-direction: column;
    gap: 0;
  }

  &--news {
    @include mq-down(lg) {
      flex-direction: column;
      gap: 0;
    }

    .p-topFlexBox__head {
      @include mq-down(lg) {
        width: 100%;
        text-align: center;
      }
    }

    .p-topFlexBox__head_btn {
      @include mq-down(lg) {
        display: none;
      }
    }

    .p-topFlexBox__body {
      @include mq-down(lg) {
        width: 100%;
      }
    }

    .p-topFlexBox__footer {
      @include mq-down(lg) {
        display: block;
        text-align: center;
        margin-top: 3rem;
      }
    }
  }
}

.p-topFlexBox__head {
  width: 22.6rem;
  @include mq-down(md) {
    width: 100%;
    text-align: center;
  }

  .c-heading {
    display: inline-block;
    text-align: center;
  }
}

.p-topFlexBox__head_btn {
  margin-top: clamp_size(30, 50);
  @include mq-down(md) {
    display: none;
  }
}

.p-topFlexBox__body {
  width: calc(100% - 22.6rem);
  @include mq-down(md) {
    width: 100%;
  }
}

.p-topFlexBox__footer {
  display: none;
  @include mq-down(md) {
    display: block;
    text-align: center;
    margin-top: 3rem;
  }
}