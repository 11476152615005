@charset "utf-8";

///////////////////////////////////////////////////////////
// 404
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.error {
  text-align: center;
}

.error__thumb {
}

.error__title {
  font-size: clamp_size(24, 32);
  font-family: $fnt_en;
  font-weight: 700;
  color: $clr_green;
  margin-top: clamp_size(25, 30);
}

.error__text {
  line-height: 1.8;
  margin-top: clamp_size(25, 30);
}

.error__btn {
  margin-top: clamp_size(35, 55);
}