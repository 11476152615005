@charset "utf-8";

///////////////////////////////////////////////////////////
// breadcrumb
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
}

.p-breadcrumb__item {
  display: flex;
  font-size: clamp_size(12, 14);

  &:not(:first-child) {
    &:before {
      content: "";
      display: inline-block;
      background: url("img/icon/icon_arrow_r.svg") no-repeat;
      background-size: cover;
      width: 2.2rem;
      height: 2.2rem;
      margin: 0 .3rem;
      transform: translateY(clamp_size(4, 7));
    }
  }

  a, span {
    font-size: clamp_size(14, 17);
  }

  a {
    position: relative;
    display: inline-block;
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: .3em;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $clr_black;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s,visibility .3s ease-in-out;
    }
  
    @include hover {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}