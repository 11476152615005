@charset "utf-8";

///////////////////////////////////////////////////////////
// archiveSelect
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-archiveSelects {
  display: flex;
  gap: 3rem;
  @include mq-down(sm) {
    flex-direction: column;
    gap: clamp_size(10, 15);
  }
}

.p-archiveSelect {
  width: 34%;
  @include mq-down(md) {
    width: 48%;
  }
  @include mq-down(sm) {
    width: 100%;
  }
}