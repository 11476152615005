
@charset "utf-8";

///////////////////////////////////////////////////////////
// columnCard
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-columnCard {
  border-radius: 10px;
  border: 2px solid $clr_green;
  background-color: #fff;
  overflow: hidden;
}

.p-columnCard__link {
  display: block;
}

.p-columnCard__thumb {
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 294 / 150;
  }
}

.p-columnCard__body {
  padding: clamp_size(10, 20);
}

.p-columnCard__date {
  time {
    font-size: clamp_size(12, 14);
    font-weight: 700;
    font-family: $fnt_en;
    color: $clr_green;
  }
}

.p-columnCard__title {
  font-size: clamp_size(14, 16);
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: .2rem;
}

.p-columnCard__text {
  line-height: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-top: .5rem;
}