@charset "utf-8";

///////////////////////////////////////////////////////////
// bgObject
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-bgObject {
  background-image: url("img/top/img_background01@2x.png");
  background-size: 100%;
  background-repeat: repeat-y;
  overflow-y: hidden;
  @include mq-down(md) {
    background-image: url("img/top/img_background02@2x.png");
  }
}