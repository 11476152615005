@charset "utf-8";

///////////////////////////////////////////////////////////
// 変数定義用SASS
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// ブレイクポイント
// ---------------------------------------------------------
$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);
$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

// ---------------------------------------------------------
// レイアウト
// ---------------------------------------------------------
// コンテナ幅
$container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px );

// 余白
$gap: 3rem; // 30px

// ---------------------------------------------------------
// z-index
// ---------------------------------------------------------
$z-hamburger: 500;
$z-nav: 400;
$z-headerBg: 350;
$z-header: 300;
$z-fixedItem: 200;

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$opacity: .6;
$transition_opacity: opacity .3s ease;

// ---------------------------------------------------------
// カラー
// ---------------------------------------------------------
// グレー系
$clr_black: #333;
$clr_gray: #F6F6F6;
$clr_middle_gray: #C3C3C3;;
$clr_light_gray01: #D9D9D9;
$clr_light_gray02: #D2D2D2;
$clr_light_gray03: #F9F9F9;
$clr_light_gray04: #E9E9E9;
$clr_dark_gray: #ABABAB;

// グリーン系
$clr_green: #00AD6F;
$clr_light_green: #F3F9F7;

// レッド系
$clr_red: #E14848;

// イエロー系
$clr_yellow: #FAE638;
$clr_light_yellow: #FFF6AA;
$clr_beige: #FFFFF3;

// オレンジ系
$clr_orange: #FACF38;

// ---------------------------------------------------------
// デフォルトフォント設定
// ---------------------------------------------------------
// フォントサイズ
$fnt_size_default: 1.4rem;

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 2;

// フォントファミリー
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Noto+Sans+JP:wght@400;500;700&display=swap');

$fnt_gothic: 'Noto Sans JP', sans-serif;;
$fnt_en: 'Montserrat', sans-serif;