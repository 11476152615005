@charset "utf-8";

///////////////////////////////////////////////////////////
// marker
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-marker {
  display: inline;
  background-image: linear-gradient(to bottom, transparent 65%, $clr_light_yellow 35%);
  // background-size: 0 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  line-height: normal;
  letter-spacing: calc(5 / 100 * 1em);
}