@charset "utf-8";

///////////////////////////////////////////////////////////
// pageTop
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-pageTop {
  position: fixed;
  right: 4%;
  bottom: clamp_size(30,60);
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s ease, visibility .35s ease;
  z-index: $z-fixedItem;

  &.is-show {
    opacity: 1;
    visibility: visible;
  }

  &.is-hidden {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

.p-pageTop__link {
  position: relative;
  display: inline-block;
  transition: $transition_opacity;

  @include hover {
    opacity: $opacity;
  }
}