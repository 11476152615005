@charset "utf-8";

///////////////////////////////////////////////////////////
// service
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.serviceContent__about {
  text-align: center;
  margin-top: clamp_size(20, 30);
}

.serviceContent__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 2px solid $clr_green;
  margin-top: 3rem;
  @include mq-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.serviceContent__item {
  & + & {
    border-left: 2px solid $clr_green;
    @include mq-down(lg) {
      border-left: 0;
    }
    @include mq-down(sm) {
      border-left: 0;
      border-top: 2px solid $clr_green;;
    }
  }

  &:nth-child(even) {
    @include mq-down(lg) {
      border-left: 2px solid $clr_green;
    }
    @include mq-down(sm) {
      border-left: 0;
    }
  }

  &:nth-child(3),
  &:nth-child(4) {
    @include mq-down(lg) {
      border-top: 2px solid $clr_green;
    }
  }

  .c-btn {
    width: 100%;
    border: none;
    color: $clr_black;

    &::after {
      color: $clr_green;
    }

    @include hover {
      color: #fff;
      background-color: $clr_green;

      &::after {
        color: #fff;
      }
    }
  }
}

.serviceContent__unit {
  margin-top: clamp_size(60, 100);
}

.serviceContent__unit_about {
  margin-top: clamp_size(15, 20);
}

.serviceContent__unit_list {
  display: grid;
  gap: clamp_size(15,30,768,1440);
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2.5rem;
  @include mq-down(md) {
    gap: 3rem;
    grid-template-columns: repeat(1, 1fr);
  }
}

.serviceContent__unit_item {
  @include mq-down(md) {
    max-width: 56rem;
    margin: 0 auto;
  }
}

.serviceContent__unit_thumb {
  img {
    width: 100%;
    border: 1px solid $clr_light_gray04;
    border-radius: 10px;
  }
}

.serviceContent__unit_title {
  margin-top: 1.5rem;
}

.serviceContent__unit_text {
  line-height: 1.6;
  margin-top: 1rem;
}

.serviceContent__unit_btn {
  text-align: center;
  margin-top: clamp_size(60, 100);
}

.serviceContent__banner {
  margin-top: clamp_size(40, 70);
}

.serviceContent__banner_link {
  display: block;
}