@charset "utf-8";

///////////////////////////////////////////////////////////
// sliderBtn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-slicerBtn {
  &--prev,
  &--next {
    width: 4.4rem !important;
    height: 4.4rem !important;
    border: 2px solid transparent;
    background-color: $clr_green;
    border-radius: 50px;
    border: 2px solid transparent;
    transition: border-color .3s, background-color .3s ease-in-out;

    &::after {
      content: "";
      mask-repeat: no-repeat;
      mask-size: cover;
      font-size: 0 !important;
      width: 2.2rem;
      height: 2.2rem;
      background-color: #fff;
      transition: background-color .3s ease-in-out;
    }

    &.swiper-button-disabled {
      background-color: $clr_light_gray01;
    }

    @include hover {
      border: 2px solid $clr_green;
      background-color: #fff;
      &::after {
        background-color: $clr_green;
      }
    }
  }

  &--prev {
    &::after {
      mask-image: url("img/icon/icon_arrow_l.svg");
      transform: translateX(-1px);
    }
  }

  &--next {
    &::after {
      mask-image: url("img/icon/icon_arrow_r.svg");
      transform: translateX(1px);
    }
  }
}