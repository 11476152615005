@charset "utf-8";

///////////////////////////////////////////////////////////
// main
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-main {
  padding-top: 11.5rem;
  @include mq-down(md) {
    padding-top: 8.5rem;
  }
  @include mq-down(sm) {
    padding-top: 6.5rem;
  }
}