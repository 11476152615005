@charset "utf-8";

///////////////////////////////////////////////////////////
// caseCard
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-caseCard {

}

.p-caseCard__link {
  display: block;
}

.p-caseCard__thumb {
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 402 / 233;
  }
}

.p-caseCard__client {
  font-size: clamp_size(12, 14);
  font-weight: 700;
  line-height: normal;
  margin-top: 1rem;

  .name {
    font-size: inherit;
    margin-right: .1em;
  }
}

.p-caseCard__title {
  font-weight: 700;
  line-height: 1.6;
  margin-top: .8rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.p-columnCard__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  margin-top: 1rem;
}