@charset "utf-8";

///////////////////////////////////////////////////////////
// tel
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-tel {
  position: relative;
  display: inline-block;
  font-size: clamp_size(24, 30);
  font-family: $fnt_en;
  font-weight: 700;
  padding-left: 1.1em;
  line-height: normal;
  transition: color .3s ease-in-out;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: clamp_size(24, 28);
    height: clamp_size(24, 28);
    background: url("img/icon/icon_tel.svg") no-repeat;
    background-size: cover;
  }

  @include hover {
    color: $clr_green;
  }
}