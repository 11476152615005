@charset "utf-8";

///////////////////////////////////////////////////////////
// single
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.article__head {
  margin-bottom: clamp_size(30, 50);
}

.article__title {
  font-size: clamp_size(24, 32);
  font-weight: 700;
  line-height: 1.3;
  margin-top: 1rem;
}

.article__backBtn {
  margin-top: clamp_size(45, 75);
  @include mq-down(sm) {
    text-align: center;
  }
}

// ---------------------------------------------------------
// 目次
// ---------------------------------------------------------
.article__outline {
  margin-top: clamp_size(40, 60);
}

.article__outline_title {
  font-size: clamp_size(18, 22);
  font-weight: 700;
  margin-bottom: .5rem;
}

// ---------------------------------------------------------
// content
// ---------------------------------------------------------
.content {
  > * {
    margin-top: 2em;
  }

  > *:first-child {
    margin-top: clamp_size(40, 60);
  }

  h2 {
    font-size: clamp_size(20, 28);
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: .8em;

    & + * {
      margin-top: 0;
    }
  }

  h3 {
    position: relative;
    font-size: clamp_size(18, 22);
    font-weight: 700;
    line-height: normal;
    padding-bottom: .5em;
    margin-bottom: .8em;

    & + * {
      margin-top: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $clr_light_gray02;
    }
  }

  h4,h5,h6 {
    font-weight: 700;
    line-height: normal;
    margin-bottom: .8em;

    & + * {
      margin-top: 0;
    }
  }

  h4 {
    font-size: clamp_size(16, 20);
    line-height: 1.6;
  }

  h5 {
    font-size: clamp_size(15, 18);
    line-height: 1.6;
  }

  h6 {
    font-size: clamp_size(14, 17);
    line-height: 1.6;
  }

  p {
    & + p,
    & + a {
      margin-top: 1.25em;
    }
  }

  strong {
    font-size: inherit;
    font-family: inherit;
    font-weight: 700;
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    & + ul,
    & + ol {
      margin-top: clamp_size(20, 40);
    }

    li {
      position: relative;
      padding-left: 1.5em;
      line-height: 1.6;
      
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        counter-increment: item;
        content: counter(item)'.';
        font-size: clamp_size(14, 16);
        color: $clr_black;
      }

      &:not(:first-child) {
        margin-top: .5em;
      }
    }
  }
  
  ul {
    list-style: none;

    & + ul,
    & + ol {
      margin-top: clamp_size(20, 40);
    }
    
    li {
      position: relative;
      font-size: clamp_size(14, 16);
      padding-left: 1.5em;
      line-height: 1.6;

      &::before {
        content: "・";
        position: absolute;
        top: 0;
        left: 0;
      }

      &:not(:first-child) {
        margin-top: .5em;
      }
    }
  }

  a {
    font-weight: 700;
    font-family: $fnt_en;
    color: $clr_green;
    text-decoration: underline;
    word-break: break-all;
    text-underline-offset: 0.2em;

    & + p,
    & + a {
      margin-top: 2rem;
    }

    & + * {
      margin-top: clamp_size(20, 40);
    }

    @include hover {
      text-decoration: none;
    }
  }

  blockquote {
    position: relative;
    margin: clamp_size(40, 60) 0 !important;
    padding: clamp_size(30, 50) clamp_size(40, 60);
    background-color: $clr_light_green;

    &::before,
    &::after {
      content: "“";
      position: absolute;
      line-height: 1;
      font-size: 4rem;
      color: $clr_green;
    }

    &::before {
      top: clamp_size(15, 25);
      left: clamp_size(12, 32);
    }

    &::after {
      bottom: clamp_size(15, 25);
      right: clamp_size(12, 32);
      transform: rotate(-180deg);
    }

    cite {
      display: block;
      font-size: 1.2rem;
      margin-top: 1em;
    }

    & + * {
      margin-top: 4rem;
    }
  }

  .wp-block-table {
    white-space: nowrap;
    overflow-x: auto;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
  
      thead {
        border-bottom: 6px solid #fff;
      }
      
      tr {
        &:nth-child(odd) {
          background-color: $clr_light_green;
        }
      }
      
      th,
      td {
        font-size: 1.3rem;
        padding: 1.3em 2.3em;
        word-break: break-word;
        box-sizing: border-box;
        border: none;
  
        small {
          display: block;
          font-size: clamp_size(14, 15);
          line-height: 1.6;
          margin-top: .5rem;
        }
      }
      
      th {
        font-weight: 500;
      }
  
      a {
        font-weight: 700;
        font-family: $fnt_en;
        color: $clr_green;
        text-decoration: underline;
        word-wrap: break-word;
        text-underline-offset: 0.2em;
    
        @include hover {
          text-decoration: none;
        }
      }
    }
  }

  .wp-block-post-featured-image {
    margin-bottom: clamp_size(30, 50);
  }

  &--case {
    h2 {
      position: relative;
      font-size: clamp_size(18, 22);
      font-weight: 700;
      line-height: normal;
      padding-bottom: .5em;
      margin-bottom: .8em;
  
      & + * {
        margin-top: 0;
      }
  
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $clr_light_gray02;
      }
    }

    h3,h4 {
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: .8em;
      padding-bottom: 0;
  
      & + * {
        margin-top: 0;
      }

      &::after {
        display: none;
      }
    }

    h3 {
      font-size: clamp_size(18, 22);
    }

    h4 {
      font-size: clamp_size(16, 18);
    }

    p {
      & + p {
        margin-top: 1em;
      }

      & + .b-caseUnit {
        margin-top: 2.4em !important;
      }
    }

    .b-caseUnit {
      & + p {
        margin-top: 2.4em !important;
      }
    }
  }

  &--case {
    max-width: 96rem;
    margin: 0 auto;
  }
}

.l-article__content {
  .wp-block-table {
    table {
      width: 100%;
    }
  }
}