@charset "utf-8";

///////////////////////////////////////////////////////////
// contactHead
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-contactHead__about {
  line-height: 1.8;
}

.p-contactHead__cautions {
  margin-top: 2rem;
}

.p-contactHead__caution {
  font-size: clamp_size(12, 13);
  padding-left: 1.2em;
  text-indent: -1.2em;
  line-height: 1.7;

  & + & {
    margin-top: .5rem;
  }

  &::before {
    content: "※";
    font-size: clamp_size(12, 13);
    line-height: 1.7;
    margin-right: .2em;
  }
}