@charset "utf-8";

///////////////////////////////////////////////////////////
// b-caseUnit
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.b-caseUnit {
  display: flex;
  gap: clamp_size(15, 20);
  // @include mq-down(sm) {
  //   flex-direction: column;
  // }

  & + & {
    margin-top: clamp_size(20, 40);
  }

  dt {
    font-weight: 700;
    white-space: nowrap;
    width: 4em;


    &.listener {
      color: $clr_orange;
    }

    &.speaker {
      color: $clr_green;
    }
  }

  dd {
    width: calc(100% - 4em);
    // @include mq-down(sm) {
    //   margin-top: -1.5rem;
    //   width: 100%;
    // }
  }

  &--narration {
    dt {
      position: relative;

      &::after {
        position: absolute;
        left: 0;
        top: clamp_size(15, 18);
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: $clr_dark_gray;
      }
    }
  }
}