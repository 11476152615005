@charset "utf-8";

///////////////////////////////////////////////////////////
// consultation
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

// ---------------------------------------------------------
// よくあるお悩み
// ---------------------------------------------------------
.consultation__problemList {
  display: grid;
  gap: 4rem 2rem;
  grid-template-columns: repeat(4, 1fr);
  margin-top: clamp_size(30, 40);
  @include mq-down(lg) {
    max-width: 62rem;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq-down(md) {
    gap: 3rem 2rem;
  }
  @include mq-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.consultation__problemItem {
  max-width: 26rem;
  margin: 0 auto;

  &:nth-child(1) {
    .consultation__problemItem_thumb {
      img {
        width: clamp_size(123, 143);
      }
    }
  }

  &:nth-child(2) {
    .consultation__problemItem_thumb {
      img {
        width: clamp_size(77, 99);
      }
    }
  }

  &:nth-child(3) {
    .consultation__problemItem_thumb {
      img {
        width: clamp_size(75, 95);
      }
    }
  }

  &:nth-child(4) {
    .consultation__problemItem_thumb {
      img {
        width: clamp_size(87, 107);
      }
    }
  }
}

.consultation__problemItem_thumb {
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 2px solid $clr_green;
  background-color: #fff;
  aspect-ratio: 1 / 1;
  @include mq-down(sm) {
    max-width: 20rem;
    margin: 0 auto;
  }
}

.consultation__problemItem_text {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8;
  text-align: center;
  margin-top: 1.5rem;
  @include mq-down(md) {
    font-size: 1.4rem;
  }
  @include mq-down(sm) {
    font-size: 1.5rem;
  }
}

.consultation__problem_message {
  line-height: 1.8;
  margin-top: clamp_size(30, 50);
  text-align: center;
}


.contact__form_head {
  margin-top: clamp_size(25, 35);
}

.contact__form_wrap {
  margin-top: clamp_size(25, 35);
}


// ---------------------------------------------------------
// 無料相談のご利用方法
// ---------------------------------------------------------
.consultation__steps {
  display: flex;
  flex-direction: column;
  gap: 5.7rem;
  @include mq-down(sm) {
    gap: 3.7rem;
  }
}

.consultation__step {

  &:nth-child(1) {
    .consultation__step_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq-down(md) {
        flex-direction: column;
      }
    }
  
    .consultation__step_title {
      width: 15%;
      min-width: 19rem;
      justify-content: center;
      @include mq-down(md) {
        width: 100%;
      }
    }
  
    .consultation__step_text {
      width: calc(100% - 19rem);
      width: 80%;
      @include mq-down(md) {
        width: 100%;
      }
    }
  }

  &:nth-child(2) {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    @include mq-down(md) {
      gap: 5.7rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.consultation__subSteps {
  display: flex;
  flex-direction: column;
  gap: 5.7rem;
  @include mq-down(sm) {
    gap: 3.7rem;
  }

  &:nth-child(2) {
    .consultation__subStep {
      &:nth-child(1) {
        &::before {
          @include mq-down(md) {
            display: none;
          }
        }
      }
    }
  }
}

.consultation__subStep {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -5.7rem);
    display: block;
    width: 5.7rem;
    height: 5.7rem;
    background-image: url("img/icon/icon_arrow_l_b.svg");
    background-repeat: no-repeat;
    background-size: cover;
    @include mq-down(sm) {
      width: 3.7rem;
      height: 3.7rem;
      transform: translate(-50%, -3.7rem);
    }
  }
}

.consultation__step_title {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  .num {
    font-size: clamp_size(24, 44);
    font-weight: 700;
    font-family: $fnt_en;
    color: $clr_green;
  }

  .text {
    margin-left: 1rem;
  }
}

.consultation__step_text {
  margin-top: 1rem;

  .text {
    line-height: 1.8;
  }

  .caution {
    font-size: clamp_size(12, 13);
    line-height: 1.7;
    margin-top: 1rem;
  }
}

.consultation__step_heading {
  padding: 1.6em 1em;
  border-radius: 5px;
  background-color: $clr_green;

  .text {
    color: #fff;
    text-align: center;
    line-height: 1.45;
  }
}