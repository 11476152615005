@charset "utf-8";

///////////////////////////////////////////////////////////
// outline
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-outline {
  ol {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 4px;
      display: block;
      width: 1px;
      height: 100%;
      background-color: $clr_light_gray02;
    }

    li {
      position: relative;
      padding-left: 2.6rem;
      line-height: 1.6;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $clr_green;
      }

      a {
        font-size: clamp_size(14, 17);
        font-weight: 500;
        color: $clr_dark_gray;
        transition: color .3s ease-in-out;

        @include hover {
          color: $clr_black;
        }

        &.is-current {
          color: $clr_black;
        }
      }
    }
  }

  &--PC {
    overflow-y: auto;
    overscroll-behavior: contain;
    max-height: 20rem;
  }

  &--SP {
    ol {
      li {
        a {
          color: $clr_black;
          font-weight: 500;

          @include hover {
            text-decoration: underline;
            text-underline-offset: .2em;
          }
        }
      }
    }
  }
}