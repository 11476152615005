@charset "utf-8";

///////////////////////////////////////////////////////////
// tagCloud
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-tagCloud {
  display: flex;
  border-radius: 50px;
  background-color: $clr_light_green;
  padding: 1rem clamp_size(25, 50);
  @include mq-down(sm) {
    flex-direction: column;
    border-radius: 10px;
  }

  .wp-tag-cloud {
    display: flex;
    gap: 1rem 1.5rem;
  
    li {
      white-space: nowrap;
  
      &::before {
        content: "＃";
        font-size: clamp_size(13, 15);
        font-weight: 500;
        color: $clr_green;
      }
  
      a {
        font-size: clamp_size(13, 15) !important;
        font-weight: 500;
        color: $clr_green;
        white-space: nowrap;
  
        @include hover {
          color: $clr_green;
          text-decoration: underline;
          text-underline-offset: .1em;
        }
      }
    }
  }
}

.p-tagCloud__title {
  width: 13rem;
  font-weight: 700;
  white-space: nowrap;
  padding: 1.1rem 0;
  @include mq-down(sm) {
    padding: 0;
    text-align: center;
    width: 100%;
  }
}

.p-tagCloud__tags {
  margin-left: 4rem;
  overflow-x: auto;
  white-space: nowrap;
  padding: 1.1rem 0;
  @include mq-down(md) {
    margin-left: 2rem;
  }
  @include mq-down(sm) {
    margin-left: 0;
    padding: 0 0 .5rem;
    margin-top: .5rem;
  }
}

.p-tagCloud__tags_wrap {
  width: calc(100% - 17rem);
  @include mq-down(md) {
    width: calc(100% - 15rem);
  }
  @include mq-down(sm) {
    width: 100%;
  }
}