@charset "utf-8";

///////////////////////////////////////////////////////////
// newsList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.p-newsItem {
  position: relative;
  border-top: 1px solid $clr_light_gray01;

  &:last-child {
    border-bottom: 1px solid $clr_light_gray01;
  }
}

.p-newsItem__link {
  display: flex;
  align-items: center;
  padding: 2rem 3rem 2rem 0;
  transition: $transition_opacity;
  @include mq-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include hover {
    opacity: $opacity;
  }
}

.p-newsItem__head {
  display: flex;
  align-items: center;
  min-width: fit-content;
  @include mq-down(sm) {
    align-items: flex-start;
  }
}

.p-newsItem__date {
  time {
    font-size: clamp_size(12, 14);
    font-weight: 700;
    font-family: $fnt_en;
  }
}

.p-newsItem__categories {
  margin-left: clamp_size(15,30,992,1440);
  margin-top: -.5rem;
  @include mq-down(lg) {
    margin-left: clamp_size(15,30,768,992);
  }
}

.p-newsItem__category {
  margin-top: .5rem;
  @include mq-down(sm) {
    display: inline-block;
  }
  
  &--green {
    .text {
      color: $clr_green;
      background-color: $clr_light_green;
    }
  }

  .text {
    display: inline-block;
    font-size: clamp_size(12, 14);
    font-weight: 700;
    border-radius: 30px;
    padding: .4em 1.25em;
    line-height: normal;
  }
}

.p-newsItem__title {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: clamp_size(15,30,992,1440);
  @include mq-down(sm) {
    margin-left: 0;
    margin-top: .8rem;
  }
}

.p-newsItem__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}