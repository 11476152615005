
///////////////////////////////////////////////////////////
// articleContainer
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.l-articleContainer {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  @include mq-down(md) {
    flex-direction: column;
    gap: clamp_size(60, 110);
  }
}

.l-article__content {
  width: calc(100% - clamp_size(255,325,768,1440) - 3rem);
  @include mq-down(md) {
    width: 100%;
  }
}

.l-article__aside {
  width: clamp_size(255,325,768,1440);
  height: fit-content;
  left: 0;
  position: sticky;
  top: 10rem;
  overflow-y: auto;
  padding: 0 1.5rem 4rem;
  overscroll-behavior: contain;
  max-height: calc(100vh - 10rem);
  @include mq-down(md) {
    position: static;
    width: 100%;
    padding: 0;
  }
}