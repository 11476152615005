@charset "utf-8";

///////////////////////////////////////////////////////////
// hoverLink
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as *;
@use "../foundation/mixins" as *;

.c-hoverLink {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0.2em;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $clr_black;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s,visibility .4s ease;
  }

  @include hover {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}